import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import './index.scss';
import LogoWide from "../components/images/logowide.tsx";
import SectionCard from "../components/widgets/sectionCard";
import LogoAPI from "../components/images/logo.api";

const IndexPage = () => (
  <Layout noHeader={true}>
    <SEO title="Documentación Fiscalpop" />
    <div id="Landing">

      <h1>
        Documentación
      </h1>
      <div className="logos">
        <LogoWide format={'icon'} />
        <LogoWide format={'text'} />
      </div>
      <h3>
        ¿Qué tipo de integración buscas?
      </h3>

      <div className="sectionLinks">
        <Link to="/api/">
        <SectionCard title="Integración por API" subtitle="Front-end, back-end, WebServices" color="#ff7c00" LogoSvg={LogoAPI} />
        </Link>
        <Link to="/ecommerce/">
          <SectionCard title="Integración para eCommerce" subtitle="MercadoLibre, Amazon, Shopify" color="#78ccfc" LogoSvg={LogoAPI} />
        </Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
